import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageLayout from "../components/layout/page/page"

const RefundPage = () => (
  <Layout>
    <SEO title="Terms & Conditions" keywords="terms, policy" />
    <PageLayout name="Refund Policy" />
    <section style={{ marginBottom: "70px" }}>
      <div className="container">
        <div className="row" style={{ padding: "0 15px" }}>
          <div className="privacy-content">
            <div className="">
              <h1 className="sans">Hungry Hippo Solutions</h1>
              <div className="d-flex">
                <p className="sans p-update">
                  URL: <Link to="/">https://www.hungryhipposolutions.com/</Link>
                </p>
              </div>
            </div>
            <p className="sans p-details">
              The website listed above, hereinafter referred to as "Website" is
              operated by the following party:
              <br />
              <br />
              Magi Media Group, a corporation, incorporated under the laws of
              the state of California (hereinafter referred to as "Operator")
              <br />
              <br />
              For any questions or comments regarding this Refund Policy,
              customers may visit the following link:
              <Link to="/"> https://www.hungryhipposolutions.com/</Link>
              <br />
              <br />
              No refunds are permitted on the sale of initial setup fees for the
              services provided. All sales are final on services that are a
              onetime fee for the service.
              <br />
              <br />
              If the service purchased has a recurring monthly fee associated
              with said service, that may be cancelled following the
              cancellation policy on the following page.
              <br />
              <br />
              Given the nature and scope of the work done, Magi Media Group and
              Hungry Hippo Solutions take a considerable amount of time to
              setup, develop, implement a new website for each client and can
              not recover that investment of time that goes into making each
              website setup a success.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default RefundPage
